import React, { useState, useEffect } from "react";
import "./Assets.css"
import { FaTrash, FaEdit } from "react-icons/fa";

export default function Assets() {

  const [Assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [serialNumberFilter, setSerialNumberFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");

  const [idTemp, setIdTemp] = useState();
  const [showEdit, setShowEdit] = useState(false);

  const BackendIP = window.location.host.split(":")[0] || "localhost";
  const BackendPort = "9094";
  const Token = getTokenFromCookie();

  const [formData, setFormData] = useState({
    Name: "",
    NetworkingIP: "",
    Location: "",
    Manufacturer: "",
    Type: "",
    Model: "",
    SerialNumber: "",
    Processor: "",
    Memory: "",
    HardDriveSize: "",
    OperatingSystem: "",
    LastUpDate: new Date().toISOString().split("T")[0],
    CreationDate: new Date().toISOString().split("T")[0]
  });

  const handleEdit = (license) => {
    setIdTemp(license.id);
  
    setFormData((prevState) => ({
      ...prevState, // Keep existing values
      Name: license.name,
      NetworkingIP: license.networkingIP,
      Location: license.location,
      Manufacturer: license.manufacturer,
      Type: license.type,
      Model: license.model,
      SerialNumber: license.serialNumber,
      Processor: license.processor,
      Memory: license.memory,
      HardDriveSize: license.hardDriveSize,
      OperatingSystem: license.operatingSystem,
      LastUpDate: new Date(license.lastUpdate * 1000).toISOString().split("T")[0], 
      CreationDate: new Date(license.creationDate * 1000).toISOString().split("T")[0] 
    }));
  
    setShowEdit(true);
  };
  
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(
          `http://${BackendIP}:${BackendPort}/api/auth/asset/view`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${Token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.status === 0 && data.data) {
          setAssets(data.data);
          setFilteredAssets(data.data);
        }
      } catch (error) {
        console.error("Unexpected error occurred while fetching Assets view:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this assets?");
    if (!confirmDelete) return;
    try {
      const response = await fetch(
        `http://${BackendIP}:${BackendPort}/api/auth/asset/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.status === 0) {
        const updatedAssets = Assets.filter((license) => license.id !== id);
        setAssets(updatedAssets);
        setFilteredAssets(updatedAssets);
        alert("Item deleted successful!");
      }
      else {
        alert("Item deleted fail!");
      }
    } catch (error) {
      console.error("Unexpected error occurred while deleteing Asset:", error);
      alert("Item deleted internal server error!");
    }
  }

  function getTokenFromCookie() {
    const cookies = document.cookie.split('; ');
    const cookieObj = cookies.find(row => row.startsWith('EAMS='));

    if (cookieObj) {
      const cookieValue = decodeURIComponent(cookieObj.split('=')[1]);
      try {
        const parsedCookie = JSON.parse(cookieValue);
        return parsedCookie.authToken;
      } catch (error) {
        console.error('Error parsing cookie:', error);
      }
    }
    return null;
  }

  const handleFilter = () => {
    let filtered = Assets;

    if (serialNumberFilter) filtered = filtered.filter((license) => license.SerialNumber.includes(serialNumberFilter));
    if (startDateFilter && endDateFilter) {
      filtered = filtered.filter(
        (license) =>
          (new Date(license.CreationDate) >= new Date(startDateFilter) && new Date(license.CreationDate) <= new Date(endDateFilter))
      );
    }

    setFilteredAssets(filtered);
  };

  const handleClear = () => {
    setSerialNumberFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
    setFilteredAssets(Assets);
  };

  const handleDownload = async () => {
    if (!Assets || Assets.length === 0) {
      alert("No assets available to download.");
      return;
    }
    const headers = Object.keys(Assets[0]).join(",") + "\n";
    const csvRows = Assets.map(asset =>
      Object.values(asset).map(value => `"${value}"`).join(",")
    ).join("\n");
    const csvContent = headers + csvRows;
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Assets.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formFields = Object.keys(formData);
  const fieldPairs = [];
  for (let i = 0; i < formFields.length; i += 2) {
    fieldPairs.push([formFields[i], formFields[i + 1]]);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(formData).some((value) => value.trim() === "")) {
      alert("Please fill in all fields.");
      return;
    }

    const Data = {
      name: formData.Name,
      networkingIP: formData.NetworkingIP,
      location: formData.Location,
      manufacturer: formData.Manufacturer,
      type: formData.Type,
      model: formData.Model,
      serialNumber: formData.SerialNumber,
      processor: formData.Processor,
      memory: formData.Memory,
      hardDriveSize: formData.HardDriveSize,
      operatingSystem: formData.OperatingSystem,
      lastUpdate: formData.LastUpDate ? Math.floor(new Date(formData.LastUpDate).getTime() / 1000) : null,
      creationDate: formData.CreationDate ? Math.floor(new Date(formData.CreationDate).getTime() / 1000) : null
    };

    try {
      const response = await fetch(`http://${BackendIP}:${BackendPort}/api/auth/asset/update/${idTemp}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Data),
      });

      const data = await response.json();

      if (response.ok && data.status === 0) {
        alert("Assets update successfully!");
        window.location.reload();
      } else {
        alert(`Error: ${data.message || "Failed to update Assets."}`);
      }
    } catch (error) {
      console.error("Unexpected error occurred while updating Assets:", error);
      alert("Unexpected error occurred. Please try again.");
    }
  };

  return (
    <>
      {!showEdit && (<div className="form-container22">
        <h2>Assets View</h2>

        {/* Filters Section */}
        <div className="filters">
          <div className="filter_data_view">
            <input type="text" placeholder="Serial Number" value={serialNumberFilter} onChange={(e) => setSerialNumberFilter(e.target.value)} />

            {/* Date Range Filter */}
            <input type="date" value={startDateFilter} onChange={(e) => setStartDateFilter(e.target.value)} />
            <input type="date" value={endDateFilter} onChange={(e) => setEndDateFilter(e.target.value)} />

            <button onClick={handleFilter} className="submit-btn">Search</button>
            <button onClick={handleClear} className="submit-btn clear-btn">Clear</button>
            <button onClick={handleDownload} className="submit-btn clear-btn">Download</button>
          </div>
        </div>

        {/* Table to Show Data */}
        <div className="table-container">
          <table className="license-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Networking - IP</th>
                <th>Location</th>
                <th>Manufacturer</th>
                <th>Type</th>
                <th>Model</th>
                <th>Serial Number</th>
                <th>Processor</th>
                <th>Memory</th>
                <th>Hard Drive Size</th>
                <th>Operating System</th>
                <th>Last Update</th>
                <th>Creation Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredAssets.length > 0 ? (
                filteredAssets.map((license) => (
                  <tr key={license.id}>
                    <td>{license.id}</td>
                    <td>{license.name}</td>
                    <td>{license.networkingIP}</td>
                    <td>{license.location}</td>
                    <td>{license.manufacturer}</td>
                    <td>{license.type}</td>
                    <td>{license.model}</td>
                    <td>{license.serialNumber}</td>
                    <td>{license.processor}</td>
                    <td>{license.memory}</td>
                    <td>{license.hardDriveSize}</td>
                    <td>{license.operatingSystem}</td>
                    <td>{new Date(license.lastUpdate * 1000).toLocaleDateString("en-IN")}</td>
                    <td>{new Date(license.creationDate * 1000).toLocaleDateString("en-IN")}</td>
                    <td>
                      <FaTrash style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => handleDelete(license.id)} />
                      <FaEdit style={{ cursor: "pointer" }} onClick={() => handleEdit(license)} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="no-data">No Assets found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>)}

      {showEdit && (
        <div className="form-container">
          <h2>Assets Upload</h2>
          <form onSubmit={handleSubmit} className="form">
            {fieldPairs.map(([field1, field2], index) => (
              <div className="form-row" key={index}>
                {field1 && (
                  <div className="form-group">
                    <label>{field1.replace(/([A-Z])/g, " $1")}:</label>
                    <input
                      type={field1.includes("Date") ? "date" : "text"}
                      name={field1}
                      value={formData[field1]}
                      onChange={handleChange}
                      required
                    />
                  </div>
                )}
                {field2 && (
                  <div className="form-group">
                    <label>{field2.replace(/([A-Z])/g, " $1")}:</label>
                    <input
                      type={field2.includes("Date") ? "date" : "text"}
                      name={field2}
                      value={formData[field2]}
                      onChange={handleChange}
                      required
                    />
                  </div>
                )}
              </div>
            ))}
            <div>
              <button type="button" className="submit-btn" onClick={() => setShowEdit(false)}>back</button>
              <button type="submit" className="submit-btn">Update</button>
            </div>
          </form>
        </div>
      )}

    </>
  )
}
