import React, { useEffect, useState } from "react";
import InputField from "../../../Helpers/InputField/InputField";
import IdeTextArea from "../../../Helpers/Textarea/IdeTextArea";
import Button from "../../../Helpers/Buttons/Button";
import { FaPlus, FaTrash } from "react-icons/fa";
import "./AMF.css";
import DataTable from "../../../Charts/DataTable";

export default function AMF({ pageName }) {

  const [activeTab, setActiveTab] = useState("editor");
  const [fileContent, setFileContent] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [fields, setFields] = useState([{ id: 1, ip: "", checked: false }]);
  const ipsArray = fields.map((field) => field.ip);
  const checkedArray = fields.map((field) => field.checked);
  const [singleList, setSingleList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const singleColumns = [{ label: "IP Adress", key: "ipAddressdata" }];
  const groupColumns = [{ label: "IP Adress", key: "ipAddressdata" }];
  const BackendIP = window.location.host.split(":")[0] || "localhost";
  const BackendPort = "9094";
  const Token  = getTokenFromCookie();

  useEffect(() => {

    const fetchIpAddressList = async () => {
      try {
        const response = await fetch(
          `http://${BackendIP}:${BackendPort}/api/auth/managment/ipAddressList`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${Token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        if (data.status === 0 && data.data) {
          if (data.data.ipAddressSingle && data.data.ipAddressGroup) {
            setSingleList(
              data.data.ipAddressSingle.map((ip) => ({ ipAddressdata: ip }))
            );

            setGroupList(
              data.data.ipAddressGroup.map((ip) => ({ ipAddressdata: ip }))
            );
          } else {
            console.log("IP address lists are missing in the response.");
          }
        }
      } catch (error) {
        console.log(
          "Unexpected error occurred while fetching IP address list."
        );
      }
    };

    fetchIpAddressList();
  }, );

  function getTokenFromCookie() {
    const cookies = document.cookie.split('; ');
    const cookieObj = cookies.find(row => row.startsWith('EAMS='));

    if (cookieObj) {
      const cookieValue = decodeURIComponent(cookieObj.split('=')[1]);
      try {
        const parsedCookie = JSON.parse(cookieValue);
        return parsedCookie.authToken;
      } catch (error) {
        console.error('Error parsing cookie:', error);
      }
    }
    return null;
  }

  const showAlertIfNoFile = () => {
    if (!isFileSelected) {
      alert("Please select a valid file first.");
      return true;
    }
    return false;
  };
  const addField = () => {
    setFields([...fields, { id: fields.length + 1, ip: "", checked: false }]);
  };

  const handleDeleteField = (id) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  const validateIP = (ip) => {
    const ipRegex =
      /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;
    return ipRegex.test(ip);
  };

  const handleInputChange = (id, value) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id
          ? {
            ...field,
            ip: value,
            error: value && !validateIP(value) ? "Invalid IP Address" : "",
          }
          : field
      )
    );
  };

  const handleCheckboxChange = (id) => {
    setFields(
      fields.map((field) =>
        field.id === id ? { ...field, checked: !field.checked } : field
      )
    );
  };

  const AddIPCall = async () => {
    if (
      !ipsArray ||
      ipsArray.length < 1 ||
      ipsArray.length !== checkedArray.length
    ) {
      alert(
        "Invalid input: IP addresses and types must be non-empty and have the same length."
      );
      return;
    }
    if (!fields[0].ip.trim() || fields[0].error) {
      alert("Please enter a correct IP address.");
      return;
    }
    const requestBody = {
      ipAddress: ipsArray,
      type: checkedArray,
    };
    try {
      const response = await fetch(
        `http://${BackendIP}:${BackendPort}/api/auth/managment/addIpAddress`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();

      if (response.ok) {
        alert(`Success: ${data.message}`);
        window.location.reload();
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      alert(`Fetch error: ${error.message}`);
    }
  };

  const handleSend = async (ipAddress) => {
    if (!ipAddress || ipAddress === "") {
      alert("Invalid input: IP address is required.");
      return;
    }
    const groupSend = ipAddress === "-1" ? true : false;
    const requestBody = {
      ipAddress: ipAddress,
      groupSend: groupSend,
    };

    try {
      const response = await fetch(
        `http://${BackendIP}:${BackendPort}/api/auth/managment/provision`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();

      if (response.ok) {
        alert(`Success: ${data.message}`);
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      alert(`Fetch error: ${error.message}`);
    }
  };

  const handleDeleteSingle = async (ipAddress) => {
    if (!ipAddress || ipAddress === "") {
      alert("Invalid input: IP address is required.");
      return;
    }

    try {
      const response = await fetch(
        `http://${BackendIP}:${BackendPort}/api/auth/managment/deleteIpAddress`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify(ipAddress),
        }
      );
      const data = await response.json();
      if (response.ok) {
        alert(`Success: ${data.message}`);
        window.location.reload();
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      alert(`Fetch error: ${error.message}`);
    }
  };

  const handleDeleteToGroupOnly = async (ipAddress) => {
    if (!ipAddress || ipAddress === "") {
      alert("Invalid input: IP address is required.");
      return;
    }

    try {
      const response = await fetch(
        `http://${BackendIP}:${BackendPort}/api/auth/managment/deleteIpAddressGroup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify(ipAddress),
        }
      );
      const data = await response.json();
      if (response.ok) {
        alert(`Success: ${data.message}`);
        window.location.reload();
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      alert(`Fetch error: ${error.message}`);
    }
  };

  const groupActions = [
    {
      label: "Delete",
      className: "delete-button",
      onClick: handleDeleteToGroupOnly,
    },
  ];

  const singleActions = [
    {
      label: "Delete",
      className: "delete-button",
      onClick: handleDeleteSingle,
    },
    { label: "Provision", className: "Provision", onClick: handleSend },
  ];

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert("Please select a valid file.");
      return;
    }
    if (!file.name.endsWith(".yaml") && !file.name.endsWith(".yml")) {
      alert("Please upload a .yaml or .yml file.");
      return;
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      setFileContent(e.target.result);
      setFileName(file.name);
      setIsFileSelected(true);
    };
    reader.readAsText(file);
  };

  const handleSave = () => {
    if (!isFileSelected) {
      alert("Please select a valid file first.");
      return;
    }
    alert(`Changes saved to ${fileName}`);
  };

  const handleSampleFile = async () => {
    try {
      const response = await fetch(`http://${BackendIP}:${BackendPort}/api/auth/configurations/download`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${Token}`,
          "Accept": "text/plain"  // Ensure the API returns text
        }
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
  
      const yamlText = await response.text(); // Get response as text
      setFileContent(yamlText);
      setFileName("sample-config.yaml");
      setIsFileSelected(true);
    } catch (error) {
      console.error("Error fetching sample file:", error);
      alert("Failed to fetch sample file. Please try again.");
    }
  };
  
  const handleDownload = () => {
    if (!isFileSelected) {
      alert("Please select a valid file first.");
      return;
    }
    const blob = new Blob([fileContent], { type: "text/yaml" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `modified-${fileName}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUpload = async () => {
    if (showAlertIfNoFile()) return;
    if (!fileContent || !fileName) {
      alert("No file selected. Please upload a file before submitting.");
      return;
    }
    if (!Token) {
      alert("Authentication token missing. Please login.");
      return;
    }

    let fileContentString = "";
    if (typeof fileContent === "string") {
      fileContentString = fileContent;
    } else if (typeof fileContent === "object") {
      fileContentString = JSON.stringify(fileContent);
    }

    const base64FileContent = btoa(fileContentString);
    const requestBody = {
      fileName: fileName,
      byteData: base64FileContent,
    };

    try {
      const response = await fetch(
        `http://${BackendIP}:${BackendPort}/api/auth/managment/fileUpload`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        alert("File uploaded successfully.");
      } else {
        alert(`Upload failed: ${responseData.message || "Unknown error"}`);
      }
    } catch (error) {
      alert("An error occurred while uploading the file.");
    }
  };

  return (
    <div className="amf-container">
      <h2 className="amf-title">{pageName} Management</h2>
      <div className="tabs-container">
        <div
          className={activeTab === "editor" ? "active" : ""}
          onClick={() => setActiveTab("editor")}
        >
          File Editor
        </div>
        <div
          className={activeTab === "form" ? "active" : ""}
          onClick={() => setActiveTab("form")}
        >
          Configuration Form
        </div>
      </div>
      {activeTab === "editor" && (
        <div>
          <div className="custom-input">
            <InputField
              type="file"
              accept=".yaml,.yml"
              onChange={handleFileUpload}
            />
            <Button 
              text="Bring Sample File"
              onClick={handleSampleFile}
            />
          </div>

          <div className="amf-textarea-container">
            <IdeTextArea
              value={fileContent}
              onChange={(e) => setFileContent(e.target.value)}
            />
          </div>
          <div className="amf-buttons-container">
            <Button
              text="Save"
              onClick={handleSave}
              disabled={!isFileSelected}
            />
            <Button
              text="Download"
              onClick={handleDownload}
              disabled={!isFileSelected}
            />
            <Button
              text="Upload"
              onClick={handleUpload}
              disabled={!isFileSelected}
            />
          </div>
        </div>
      )}
      {activeTab === "form" && (
        <div className="config-form-container">
          <div className="config-form-header">
            <h3>Configuration Form</h3>
            <FaPlus className="config-form-add-icon" onClick={addField} />
          </div>

          {/* Dynamic Input Fields */}
          {fields.map((field, index) => (
            <div key={field.id} className="config-form-input-row">
              <input
                type="text"
                placeholder="Enter IP Address"
                value={field.ip}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                className={`config-form-input ${field.error ? "error" : ""}`}
              />
              <input
                type="checkbox"
                checked={field.checked}
                onChange={() => handleCheckboxChange(field.id)}
                className="config-form-checkbox"
                disabled={!field.ip || field.error}
              />
              {index !== 0 && (
                <FaTrash
                  className="config-form-delete-icon"
                  onClick={() => handleDeleteField(field.id)}
                />
              )}
              {field.error && (
                <span className="error-message">{field.error}</span>
              )}
            </div>
          ))}

          {/* Buttons */}
          <div className="config-form-button-container">
            <button className="config-form-button" onClick={AddIPCall}>
              Add to Ansible
            </button>
          </div>
          <div className="config-list-container">
            <div className="single-ip-list config-list">
              <h3>Single IP List</h3>
              <DataTable
                columns={singleColumns}
                data={singleList}
                actions={singleActions}
              />
            </div>
            <div className="group-ip-list config-list">
              <h3>Group IP List</h3>
              <DataTable
                columns={groupColumns}
                data={groupList}
                actions={groupActions}
              />
              {groupList.length > 0 && (
                <Button
                  text={"Provision"}
                  type="submit"
                  onClick={() => {
                    handleSend("-1");
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
