import React, { useState, useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import yaml from "js-yaml";
import "./TextArea.css";

const IdeTextArea = ({ placeholder = "", value, onChange, name, height = "400px", width = "100%" }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [error, setError] = useState("");
  const textareaRef = useRef(null);
  const containerRef = useRef(null);

  // Adjust textarea height dynamically
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [value, isEditable]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [value]);

  const handleChange = (e) => {
    const text = e.target.value;
    onChange(e);
    try {
      yaml.load(text);
      setError("");
    } catch (err) {
      setError(err.message);
    }
    adjustTextareaHeight();
  };

  return (
    <div className="ide-container" style={{ height, width }} ref={containerRef}>
      <div className="toggle-edit" style={{ position: "sticky", top: 0, backgroundColor: "#252526", zIndex: 1 }}>
        <label>
          <input type="checkbox" checked={isEditable} onChange={() => setIsEditable(!isEditable)} /> Enable Editing
        </label>
      </div>
      
        
        <div className="text-editor">
          {isEditable ? (
            <textarea
              ref={textareaRef}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              name={name}
              className="custom-textarea ide-textarea"
            ></textarea>
          ) : (
            <SyntaxHighlighter language="yaml" style={oneDark} showLineNumbers wrapLines className="syntax-highlighter">
              {value || placeholder}
            </SyntaxHighlighter>
          )}
        </div>
        {error && <div className="error-message">Syntax Error: {error}</div>}
    </div>
  );
};

export default IdeTextArea;