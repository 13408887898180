import React, { useState, useEffect } from "react";
import "./Licenses.css";
import { FaTrash, FaEdit } from "react-icons/fa";

export default function Licenses() {

  const [licenses, setLicenses] = useState([]);
  const [filteredLicenses, setFilteredLicenses] = useState([]);
  const [majorField, setMajorField] = useState("");
  const [subField, setSubField] = useState("");
  const [venderFilter, setVenderFilter] = useState("");
  const [activeExpiredFilter, setActiveExpiredFilter] = useState("");
  const [serialNumberFilter, setSerialNumberFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const BackendIP = window.location.host.split(":")[0] || "localhost";
  const BackendPort = "9094";
  const Token = getTokenFromCookie();
  const [showEdit, setShowEdit] = useState(false);
  const [nameField, setNameField] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [version, setVersion] = useState(1);
  const [vender, setVender] = useState("");
  const [compatibleOS, setCompatibleOS] = useState("");
  const [location, setLocation] = useState("");
  const [ownership, setOwnership] = useState("");
  const [branch, setBranch] = useState("");
  const [username, setUsername] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [count, setCount] = useState("");
  const [idTemp, setIdTemp] = useState();
  const venderOptions = ["OEM", "Self"];
  const compatibleOSOptions = ["Windows", "Linux"];
  const majorFieldData = ["operatingSystem", "Communication", "Security", "MSWordEnterprise", "Other"];
  const subFieldData = {
    operatingSystem: ["OS", "Other"],
    Communication: ["Extension", "Pri", "Sip trunk", "Voice mail", "Conference", "Queue", "FAX", "Unified Communication", "Agents", "Logger", "Sapcs", "Codeblue", "Lvr", "Other"],
    Security: ["SSL", "Other"],
    MSWordEnterprise: ["Call center acd", "Other"],
    Other: ["Custom License"],
  };

  useEffect(() => {

    const fetchData = async () => {

      try {
        const response = await fetch(
          `http://${BackendIP}:${BackendPort}/api/auth/licenses/view`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${Token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.status === 0 && data.data) {
          setLicenses(data.data);
          setFilteredLicenses(data.data);
        }
      } catch (error) {
        console.log(
          "Unexpected error occurred while fetching licenses view ."
        );
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this license?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `http://${BackendIP}:${BackendPort}/api/auth/licenses/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to delete license: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.status === 0) {
        const updatedLicenses = licenses.filter((license) => license.id !== id);
        setLicenses(updatedLicenses);
        setFilteredLicenses(updatedLicenses);

        alert("Deleted successfully!");
      } else {
        alert("Failed to delete license.");
      }
    } catch (error) {
      console.error("Unexpected error occurred while deleting the license:", error);
      alert("An error occurred while deleting the license.");
    }
  };

  function getTokenFromCookie() {
    const cookies = document.cookie.split('; ');
    const cookieObj = cookies.find(row => row.startsWith('EAMS='));

    if (cookieObj) {
      const cookieValue = decodeURIComponent(cookieObj.split('=')[1]);
      try {
        const parsedCookie = JSON.parse(cookieValue);
        return parsedCookie.authToken;
      } catch (error) {
        console.error('Error parsing cookie:', error);
      }
    }
    return null;
  }

  const checkStatus = (expireDate) => {
    return new Date(expireDate) >= new Date() ? "Active" : "Expired";
  };

  const handleFilter = () => {
    let filtered = licenses;

    if (majorField) filtered = filtered.filter((license) => license.majorField === majorField);
    if (subField) filtered = filtered.filter((license) => license.subField === subField);
    if (serialNumberFilter) filtered = filtered.filter((license) => license.serialNumber.includes(serialNumberFilter));
    if (activeExpiredFilter) filtered = filtered.filter((license) => checkStatus(license.expireDate) === activeExpiredFilter);
    if (venderFilter) filtered = filtered.filter((license) => license.vender === venderFilter);

    if (startDateFilter && endDateFilter) {
      filtered = filtered.filter(
        (license) =>
          (new Date(license.issueDate) >= new Date(startDateFilter) && new Date(license.issueDate) <= new Date(endDateFilter)) ||
          (new Date(license.expireDate) >= new Date(startDateFilter) && new Date(license.expireDate) <= new Date(endDateFilter))
      );
    }

    setFilteredLicenses(filtered);
  };

  const handleClear = () => {
    setMajorField("");
    setSubField("");
    setVenderFilter("");
    setActiveExpiredFilter("");
    setSerialNumberFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
    setFilteredLicenses(licenses);
  };

  const handleMajorChange = (e) => {
    setMajorField(e.target.value);
    setSubField("");
  };

  const handleDownload = async () => {
    if (!licenses || licenses.length === 0) {
      alert("No licenses available to download.");
      return;
    }
    const headers = Object.keys(licenses[0]).join(",") + "\n";
    const csvRows = licenses.map(asset =>
      Object.values(asset).map(value => `"${value}"`).join(",")
    ).join("\n");
    const csvContent = headers + csvRows;
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "licenses.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEdit = (license) => {
    setIdTemp(license.id);
    setMajorField(license.majorField);
    setSubField(license.subField);
    setBranch(license.branch);
    setCompatibleOS(license.compatibleOS);
    setCount(license.count);
    setSerialNumber(license.serialNumber);
    setVender(license.vender);
    setIssueDate(new Date(license.issueDate * 1000).toISOString().split('T')[0]); 
    setExpireDate(new Date(license.expireDate * 1000).toISOString().split('T')[0]); 
    setLocation(license.location);
    setOwnership(license.ownership);
    setUsername(license.username);
    setNameField(license.nameField);
    setShowEdit(true);
  }

  const handleSubmit = async () => {

    if (!majorField || !subField || !nameField || !issueDate || !expireDate || !version || !vender || !compatibleOS || !location || !ownership || !branch || !username || !serialNumber || !count) {
      alert("Please fill in all fields.");
      return;
    }

    if (new Date(issueDate) > new Date(expireDate)) {
      alert("Issue Date cannot be later than Expire Date.");
      return;
    }

    const formData = {
      majorField,
      subField,
      nameField,
      issueDate: issueDate ? Math.floor(new Date(issueDate).getTime() / 1000) : null,
      expireDate: expireDate ? Math.floor(new Date(expireDate).getTime() / 1000) : null,
      version,
      vender,
      compatibleOS,
      location,
      ownership,
      branch,
      username,
      serialNumber,
      count,
    };

    try {
      const response = await fetch(`http://${BackendIP}:${BackendPort}/api/auth/licenses/update/${idTemp}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok && data.status === 0) {
        alert("License update successfully!");
        window.location.reload();
      } else {
        alert(`Error: ${data.message || "Failed to update license."}`);
      }
    } catch (error) {
      console.error("Unexpected error occurred while updating license:", error);
      alert("Unexpected error occurred. Please try again.");
    }
  };

  return (
    <>
      {!showEdit && (<div className="form-container22">
        <h2>Licenses View</h2>

        {/* Filters Section */}
        <div className="filters">
          <div className="filter_data_view">
            <select value={majorField} onChange={(e) => setMajorField(e.target.value)}>
              <option value="">Major Field</option>
              <option value="Operating System">Operating System</option>
              <option value="Communication">Communication</option>
            </select>

            <select value={subField} onChange={(e) => setSubField(e.target.value)} disabled={!majorField}>
              <option value="">Sub Field</option>
              {majorField === "Operating System" && <option value="Windows">Windows</option>}
              {majorField === "Communication" && <option value="VoIP">VoIP</option>}
            </select>

            <input type="text" placeholder="Serial Number" value={serialNumberFilter} onChange={(e) => setSerialNumberFilter(e.target.value)} />

            {/* Active/Expired Filter */}
            <select value={activeExpiredFilter} onChange={(e) => setActiveExpiredFilter(e.target.value)}>
              <option value="">Status</option>
              <option value="Active">Active</option>
              <option value="Expired">Expired</option>
            </select>

            <select value={venderFilter} onChange={(e) => setVenderFilter(e.target.value)}>
              <option value="">Vender</option>
              <option value="OEM">OEM</option>
              <option value="Self">Self</option>
            </select>

            {/* Date Range Filter */}
            <input type="date" value={startDateFilter} onChange={(e) => setStartDateFilter(e.target.value)} />
            <input type="date" value={endDateFilter} onChange={(e) => setEndDateFilter(e.target.value)} />

            <button onClick={handleFilter} className="submit-btn">Search</button>
            <button onClick={handleClear} className="submit-btn clear-btn">Clear</button>
            <button onClick={handleDownload} className="submit-btn clear-btn">Download</button>
          </div>
        </div>

        {/* Table to Show Data */}
        <div className="table-container">
          <table className="license-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Major Field</th>
                <th>Sub Field</th>
                <th>Name</th>
                <th>Issue Date</th>
                <th>Expire Date</th>
                <th>Version</th>
                <th>Vender</th>
                <th>Compatible OS</th>
                <th>Location</th>
                <th>Ownership</th>
                <th>Branch</th>
                <th>Username</th>
                <th>Serial Number</th>
                <th>Count</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredLicenses.length > 0 ? (
                filteredLicenses.map((license) => (
                  <tr key={license.id}>
                    <td>{license.id}</td>
                    <td>{license.majorField}</td>
                    <td>{license.subField}</td>
                    <td>{license.nameField}</td>
                    <td>{new Date(license.issueDate * 1000).toISOString().split('T')[0]}</td>
                    <td>{new Date(license.expireDate * 1000).toISOString().split('T')[0]}</td>
                    <td>{license.version}</td>
                    <td>{license.vender}</td>
                    <td>{license.compatibleOS}</td>
                    <td>{license.location}</td>
                    <td>{license.ownership}</td>
                    <td>{license.branch}</td>
                    <td>{license.username}</td>
                    <td>{license.serialNumber}</td>
                    <td>{license.count}</td>
                    <td className={checkStatus(license.expireDate) === "Expired" ? "status-expired" : "status-active"}>
                      {checkStatus(license.expireDate)}
                    </td>
                    <td>
                      <FaTrash style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => handleDelete(license.id)} />
                      <FaEdit style={{ cursor: "pointer" }} onClick={() => handleEdit(license)} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="no-data">No licenses found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>)}

      {showEdit && (
        <div className="form-container">
          <h2>Licenses update</h2>
          <form className="form">

            {/* First Row */}
            <div className="form-row">
              <div className="form-group">
                <label>Major Field:</label>
                <select value={majorField} onChange={handleMajorChange} required style={{ width: "95%" }}>
                  <option value="">Select Major Field</option>
                  {majorFieldData.map((field) => (
                    <option key={field} value={field}>{field}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Sub Field:</label>
                <select value={subField} onChange={(e) => setSubField(e.target.value)} required disabled={!majorField} style={{ width: "95%" }}>
                  <option value="">Select Sub Field</option>
                  {majorField && subFieldData[majorField].map((sub) => (
                    <option key={sub} value={sub}>{sub}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Vender:</label>
                <select value={vender} onChange={(e) => setVender(e.target.value)} required style={{ width: "95%" }}>
                  <option value="">Select Vender</option>
                  {venderOptions.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Compatible OS:</label>
                <select value={compatibleOS} onChange={(e) => setCompatibleOS(e.target.value)} required style={{ width: "95%" }}>
                  <option value="">Select Compatible OS</option>
                  {compatibleOSOptions.map((os) => (
                    <option key={os} value={os}>{os}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* Second Row */}
            <div className="form-row">
              <div className="form-group">
                <label>License Name:</label>
                <input type="text" value={nameField} onChange={(e) => setNameField(e.target.value)} required />
              </div>

              <div className="form-group">
                <label>Version:</label>
                <input type="number" value={version} onChange={(e) => setVersion(parseInt(e.target.value) || 1)} required />
              </div>
            </div>

            {/* Third Row */}
            <div className="form-row">
              <div className="form-group">
                <label>Issue Date:</label>
                <input type="date" value={issueDate} onChange={(e) => setIssueDate(e.target.value)} required />
              </div>

              <div className="form-group">
                <label>Expire Date:</label>
                <input type="date" value={expireDate} onChange={(e) => setExpireDate(e.target.value)} required />
              </div>
            </div>

            {/* Fifth Row */}
            <div className="form-row">
              <div className="form-group">
                <label>Location:</label>
                <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
              </div>

              <div className="form-group">
                <label>Ownership:</label>
                <input type="text" value={ownership} onChange={(e) => setOwnership(e.target.value)} required />
              </div>
            </div>

            {/* Sixth Row */}
            <div className="form-row">
              <div className="form-group">
                <label>Branch:</label>
                <input type="text" value={branch} onChange={(e) => setBranch(e.target.value)} required />
              </div>

              <div className="form-group">
                <label>Username:</label>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
              </div>
            </div>

            {/* Seventh Row */}
            <div className="form-row">
              <div className="form-group">
                <label>Serial Number:</label>
                <input type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} required />
              </div>

              <div className="form-group">
                <label>Count:</label>
                <input type="number" value={count} onChange={(e) => setCount(parseInt(e.target.value) || 1)} required />
              </div>
            </div>
            <div>
              <button type="button" className="submit-btn" onClick={() => setShowEdit(false)}>back</button>
              <button type="button" className="submit-btn" onClick={handleSubmit}>Upload</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
