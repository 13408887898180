import { AiOutlineDashboard, AiOutlineSetting } from "react-icons/ai";
import { GiNetworkBars } from "react-icons/gi";
import { TbLicense } from "react-icons/tb";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdDevices, MdAssignment } from "react-icons/md";
import { FaUser } from "react-icons/fa";

// Example functions for menu items
const handleDashboardClick = () => {
  console.log("Dashboard clicked!");
};

const handleAMFClick = () => {
  console.log("AMF clicked! Navigating...");
  window.location.href = "/5g-management/amf";
};

const handleTicketClick = () => {
  alert("Ticket menu clicked!");
};

export const sidebarData = {
  Dashboard: [
    { path: "/Dashboard", icon: <AiOutlineDashboard color="#007bff" />, onClick: handleDashboardClick, disabled: false },
    { label: "Overview", path: "/dashboard/overview", icon: <AiOutlineDashboard color="#007bff" />, onClick: handleDashboardClick, disabled: false },
    { label: "Analytics", path: "/dashboard/analytics", icon: <AiOutlineSetting color="#ff9800" />, }, // Example disabled item
    { label: "Reports", path: "/dashboard/reports", icon: <TbLicense color="#9c27b0" />, disabled: false },
  ],
  Assets: [
    { path: "/assets", icon: <MdDevices color="#2196f3" />, disabled: false },
    { label: "Assets Upload", path: "/assets/upload", icon: <TbLicense color="#4caf50" />, disabled: false }
  ],
  "5G_Management": [
    { path: "/5g-management", icon: <GiNetworkBars color="green" />, disabled: false },
    { label: "UPF", path: "/5g-management/upf", icon: <GiNetworkBars color="green" />, disabled: false },
    { label: "AMF", path: "/5g-management/amf", icon: <GiNetworkBars color="green" />, onClick: handleAMFClick, disabled: false },
    { label: "SMF", path: "/5g-management/smf", icon: <GiNetworkBars color="green" />, disabled: true }, // Disabled item
    { label: "GnodeB", path: "/5g-management/gnodeb", icon: <GiNetworkBars color="green" />, disabled: false },
  ],
  Configuration: [
    { path: "/Configuration", icon: <BsFillTelephoneFill color="#f44336" />, disabled: false },
  ],
  Ticketing: [
    { path: "/ticketing", icon: <MdAssignment color="#2196f3" />, onClick: handleTicketClick, disabled: false },
    { label: "Tickets", path: "/ticketing/tickets", icon: <MdAssignment color="#ff5722" />, disabled: false },
    { label: "Open Tickets", path: "/ticketing/open", icon: <MdAssignment color="#ff5722" />, disabled: false },
    { label: "Closed Tickets", path: "/ticketing/closed", icon: <MdAssignment color="#795548" />, disabled: false },
  ],
  Licenses: [
    { path: "/licenses", icon: <TbLicense color="#673ab7" />, disabled: false },
    { label: "Upload Licenses", path: "/licenses/upload", icon: <TbLicense color="#673ab7" />, disabled: false }
  ],
  NMS: [
    { path: "/nms", icon: <TbLicense color="#009688" />, disabled: false }
  ],
  "User_Management": [
    { path: "/user-management", icon: <FaUser color="#e91e63" />, disabled: false },
    { label: "Create Users", path: "/user-management/create", icon: <FaUser color="#e91e63" />, disabled: false },
    { label: "Active Users", path: "/user-management/active", icon: <FaUser color="#3f51b5" />, disabled: false },
  ],
};
