import React from "react";

const Button = ({ text, onClick, type = "button", style = {}, disabled = false }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      style={style}
      disabled={disabled}
      className="custom-button"
    >
      {text}
    </button>
  );
};

export default Button;
