import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { useNavigate } from "react-router-dom";

// Dashboards import's
import Dashboard from "./Components/Dashboards/Dashboard/Dashboard";

// Management5G import's
import Management5G from "./Components/Dashboards/Management5G/Management5G";
import AMF from "./Components/Pages/Management5G/AMF/AMF";
import Ticketing from "./Components/Dashboards/Ticketing/Ticketing";
import Licenses from "./Components/Dashboards/Licenses/Licenses";
import LicensesUpload from "./Components/Dashboards/Licenses/LicensesUpload";
import UserManagement from "./Components/Dashboards/UserManagement/UserManagement";

// Configuration import's
import Configutation from "./Components/Pages/Configuration/index";

// Assets import's
import Assets from "./Components/Dashboards/Assets/Assets";
import AssetsUpload from "./Components/Dashboards/Assets/AssestsUpload";

// NMS import's
import NMS from "./Components/Pages/NMS/nms";

// Topbar import's
import { TopBar } from "./Components/Topbar/Topbar";
import { TopNavbar } from "./Components/TopNavBar/TopNavBar";

// Login import's
import Login from "./Components/Pages/Login/login";

function Layout() {
  
  const location = useLocation();
  const hideNav = location.pathname === "/";
  const BackendIP = window.location.host.split(":")[0] || "localhost"; 
  const BackendPort = "9094";
  const Token = getTokenFromCookie();
  const navigate = useNavigate();

  useEffect(() => {

    if(Token == null){
      navigate("/");
      return;
    }
    
    const fetchCheckAuth = async () => {
      try {
        const response = await fetch(
          `http://${BackendIP}:${BackendPort}/api/auth/checkAuth`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${Token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (data.status !== 0) {
          navigate('/');
        }
      } catch (error) {
        console.log("Unexpected error occurred while checking auth.");
      }
    };

    if (Token) {
      fetchCheckAuth();
    } else {
      navigate('/');
    }
  }, );

  function getTokenFromCookie() {
    const cookies = document.cookie.split('; ');
    const cookieObj = cookies.find(row => row.startsWith('EAMS='));

    if (cookieObj) {
      const cookieValue = decodeURIComponent(cookieObj.split('=')[1]);
      try {
        const parsedCookie = JSON.parse(cookieValue);
        return parsedCookie.authToken;
      } catch (error) {
        console.error('Error parsing cookie:', error);
      }
    }
    return null;
  }

  return (
    <div className="App">
      {!hideNav && <TopBar />}
      {!hideNav && <TopNavbar />}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />

        {/* Assets Paths and Routes */}
        <Route path="/assets" element={<Assets />} />
        <Route path="/assets/upload" element={<AssetsUpload />} />

        {/* Configuration Paths and Routes */}
        <Route path="/Configutation" element={<Configutation />} />

        {/* NMS Paths and Routes */}
        <Route path="/nms" element={<NMS />} />

        {/* 5G Management Path and Routes */}
        <Route path="/5g-management" element={<Management5G />} />
        <Route path="/5g-management/upf" element={<AMF pageName={"UPF"} />} />
        <Route path="/5g-management/amf" element={<AMF pageName={"AMF"} />} />
        <Route path="/5g-management/smf" element={<AMF pageName={"SMF"} />} />
        <Route path="/5g-management/gnodeb" element={<AMF pageName={"GnodeB"} />} />

        {/* Ticketing Paths and Routes */}
        <Route path="/ticketing" element={<Ticketing />} />

        {/* License Paths and Routes */}
        <Route path="/licenses" element={<Licenses />} />
        <Route path="/licenses/upload" element={<LicensesUpload />} />

        {/* User Management Paths and Routes */}
        <Route path="/user-management" element={<UserManagement />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
}

export default App;
