export default {
  welcomeOptions: [
    { label: 'Create New Ticket', value: 'new_ticket' },
    { label: 'FAQ', value: 'faq' },
    { label: 'System Status', value: 'system_status' }
  ],
  ticketCategories: [
    { label: 'Hardware Issue', value: 'hardware' },
    { label: 'Software Issue', value: 'software' },
    { label: 'Network Problem', value: 'network' }
  ],
  faqCategories: [
    { label: 'Account Issues', value: 'account' },
    { label: 'System Setup', value: 'setup' },
    { label: 'Troubleshooting', value: 'troubleshoot' }
  ]
};