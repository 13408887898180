import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LuMenu } from "react-icons/lu";
import Sidebar from "../Sidebar/Sidebar";
import { sidebarData } from "../Sidebar/SidebarData";
import "./TopNavBar.css";

export const TopNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Retrieve active tab from localStorage or default to "Dashboard"
  const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "Dashboard");

  useEffect(() => {
    // Update active tab based on current URL
    const currentTab = Object.keys(sidebarData).find((key) =>
      sidebarData[key].some((item) => item.path === location.pathname)
    );

    if (currentTab) {
      setActiveTab(currentTab);
      localStorage.setItem("activeTab", currentTab); // Store in localStorage
    }
  }, [location.pathname]); // Runs when URL changes

  const menuItems = Object.keys(sidebarData).map((key) => ({
    label: key,
    icon: sidebarData[key][0]?.icon,
    path: sidebarData[key][0]?.path,
  }));

  const handleMenuClick = (item) => {
    setActiveTab(item.label);
    localStorage.setItem("activeTab", item.label); // Save selected tab
    setIsSidebarOpen(true);
    if (item.path) {
      navigate(item.path);
    }
  };

  return (
    <div className="top-navbar">
      <div
        className="nav-item menu-toggle"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <LuMenu />
      </div>
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={`nav-item ${activeTab === item.label ? "active" : ""}`}
          onClick={() => handleMenuClick(item)}
        >
          {item.icon}
          <span className="nav-label">{item.label}</span>
        </div>
      ))}
      <div ref={sidebarRef}>
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          activeTab={activeTab}
        />
      </div>
    </div>
  );
};
