import React, { useState } from "react";
import "./Assets.css";

export default function AssetsUpload() {

    const [formData, setFormData] = useState({
        Name: "",
        NetworkingIP: "",
        Location: "",
        Manufacturer: "",
        Type: "",
        Model: "",
        SerialNumber: "",
        Processor: "",
        Memory: "",
        HardDriveSize: "",
        OperatingSystem: "",
        LastUpDate: new Date().toISOString().split("T")[0],
        CreationDate: new Date().toISOString().split("T")[0]
    });

    const BackendIP = window.location.host.split(":")[0] || "localhost";
    const BackendPort = "9094";
    const Token = getTokenFromCookie();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    }; 

    function getTokenFromCookie() {
        const cookies = document.cookie.split('; ');
        const cookieObj = cookies.find(row => row.startsWith('EAMS='));

        if (cookieObj) {
            const cookieValue = decodeURIComponent(cookieObj.split('=')[1]);
            try {
                const parsedCookie = JSON.parse(cookieValue);
                return parsedCookie.authToken;
            } catch (error) {
                console.error('Error parsing cookie:', error);
            }
        }
        return null;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (Object.values(formData).some((value) => value.trim() === "")) {
            alert("Please fill in all fields.");
            return;
        }

        const Data = {
            Name: formData.Name,
            NetworkingIP: formData.NetworkingIP,
            Location: formData.Location,
            Manufacturer: formData.Manufacturer,
            Type: formData.Type,
            Model: formData.Model,
            SerialNumber: formData.SerialNumber,
            Processor: formData.Processor,
            Memory: formData.Memory,
            HardDriveSize: formData.HardDriveSize,
            OperatingSystem: formData.OperatingSystem,
            LastUpDate: formData.LastUpDate ? Math.floor(new Date(formData.LastUpDate).getTime() / 1000) : null,
            CreationDate: formData.CreationDate ? Math.floor(new Date(formData.CreationDate).getTime() / 1000) : null
        };

        try {
            const response = await fetch(`http://${BackendIP}:${BackendPort}/api/auth/asset/upload`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(Data),
            });

            const data = await response.json();

            if (response.ok && data.status === 0) {
                alert("Assets uploaded successfully!");
            } else {
                alert(`Error: ${data.message || "Failed to upload Assets."}`);
            }
        } catch (error) {
            console.error("Unexpected error occurred while uploading Assets:", error);
            alert("Unexpected error occurred. Please try again.");
        }
    };

    // Split fields into pairs for a two-column layout
    const formFields = Object.keys(formData);
    const fieldPairs = [];
    for (let i = 0; i < formFields.length; i += 2) {
        fieldPairs.push([formFields[i], formFields[i + 1]]);
    }

    return (
        <div className="form-container">
            <h2>Assets Upload</h2>
            <form onSubmit={handleSubmit} className="form">
                {fieldPairs.map(([field1, field2], index) => (
                    <div className="form-row" key={index}>
                        {field1 && (
                            <div className="form-group">
                                <label>{field1.replace(/([A-Z])/g, " $1")}:</label>
                                <input
                                    type={field1.includes("Date") ? "date" : "text"}
                                    name={field1}
                                    value={formData[field1]}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        )}
                        {field2 && (
                            <div className="form-group">
                                <label>{field2.replace(/([A-Z])/g, " $1")}:</label>
                                <input
                                    type={field2.includes("Date") ? "date" : "text"}
                                    name={field2}
                                    value={formData[field2]}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        )}
                    </div>
                ))}

                <button type="submit" className="submit-btn">Upload</button>
            </form>
        </div>
    );
}
