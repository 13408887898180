import React, { useEffect, useState } from "react";
import "./nms.jsx";

export default function nms () {
    return (
        <>
            NMS
        </>
    )
}
