import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../Image/logoDark.png";
import "./login.css";

export default function Login() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confiremPassword, setConfiremPassword] = useState("");
    const [role, setRole] = useState("");
    const [show, setShow] = useState(false);
    const [showname, setShowName] = useState("Login");
    const navigate = useNavigate();
    const BackendIP = window.location.host.split(":")[0] || "localhost";
    const BackendPort = "9094";

    const handleLogin = async () => {
        try {
            const response = await fetch(
                `http://${BackendIP}:${BackendPort}/api/auth/login`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ username, password }),
                }
            );
            const data = await response.json();

            if (data.status === 0) {
                const cookieValue = JSON.stringify({ authToken: data.data.token, userRole: data.data.roles, username: data.data.username });
                document.cookie = `EAMS=${encodeURIComponent(cookieValue)}; path=/; max-age=86400`;
                navigate("/Dashboard");
            } else {
                alert("Login failed. Please check your credentials.");
            }
        } catch (error) {
            alert("An error occurred. Please try again later.");
        }
    };

    const diverter = async (e) => {
        e.preventDefault();
        console.log(show);
        if (show) {
            await handleSignUp();
        } else {
            await handleLogin();
        }
    }

    const handleSignUp = async () => {

        if (confiremPassword !== password) {
            alert("Confirem password can not match with password!");
            return;
        }
        try {
            const response = await fetch(
                `http://${BackendIP}:${BackendPort}/api/auth/signup`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ username, password, roles: role }),
                }
            );
            const data = await response.json();
            alert(data.status === 0 ? `SignUp: ${data.message}` : `SignUp failed: ${data.message}`);
        } catch (error) {
            alert("An error occurred. Please try again later.");
        }
    }

    const ShowData = () => {
        setShow(!show);
        show ? setShowName("Login") : setShowName("SignUp");
    }

    return (
        <>
            <div className="login-container">
                <div className="ImageLogo">
                    <img src={Image} alt="imageLogo" />
                </div>
                <div className="blueline">
                    <h1>{showname}</h1>
                    <form onSubmit={diverter}>
                        <div>
                            <p>Username:</p>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        {show && (
                            <div>
                                <p>Roles:</p>
                                <select className="buttonLogin" style={{ backgroundColor: "#333" }} value={role} onChange={(e) => setRole(e.target.value)} required>
                                    <option value="" disabled>Select Role</option>
                                    {["admin", "manager", "employee"].map((option) => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div>
                            <p>Password:</p>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        {show && (
                            <div>
                                <p>Confirm Password:</p>
                                <input
                                    type="password"
                                    value={confiremPassword}
                                    onChange={(e) => setConfiremPassword(e.target.value)}
                                    required
                                />
                            </div>
                        )}
                        <button className="buttonLogin" type="submit">{showname}</button>
                        <button className="buttonLogin" type="button" onClick={ShowData}>{show ? "GO to Login" : "Go to SingUp"}</button>
                    </form>
                </div>
            </div>
        </>
    );
}
