import React from "react";
import "./DataTable.css";

const DataTable = ({ columns, data, actions = [] }) => {
  return (
    <div className="table-container">
      <table className="data-table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} className="table-header">
                {column.label}
              </th>
            ))}
            {actions.length > 0 && <th className="table-header">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className="table-row">
              {columns.map((column, colIndex) => (
                <td key={colIndex} className="table-cell">
                  {Array.isArray(row[column.key])
                    ? row[column.key].join(", ")
                    : row[column.key]}
                </td>
              ))}
              {actions.length > 0 && (
                <td className="table-cell action-cell">
                  {actions.map((action, actionIndex) => (
                    <button
                      key={actionIndex}
                      className={`action-button ${action.className}`}
                      onClick={() => action.onClick(row.ipAddressdata)}
                    >
                      {action.label}
                    </button>
                  ))}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
