import React, { useState, useEffect, useRef } from 'react';
import { FaCommentDots, FaTimes } from 'react-icons/fa';
import './Chatbot.css';
import ChatbotData from './ChatbotData';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [currentStep, setCurrentStep] = useState('welcome');
  const [ticketData, setTicketData] = useState({});
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (isOpen && messages.length === 0) {
      setMessages([{
        type: 'bot',
        content: 'Hello! I am your helpdesk assistant. How can I help you today?',
        options: ChatbotData.welcomeOptions
      }]);
    }
  }, [isOpen]);

  const handleOptionSelect = (option) => {
    const newMessage = { type: 'user', content: option.label };
    
    setMessages(prev => [...prev, newMessage]);
    
    switch(option.value) {
      case 'new_ticket':
        setCurrentStep('category');
        setMessages(prev => [...prev, {
          type: 'bot',
          content: 'Please select the ticket category:',
          options: ChatbotData.ticketCategories
        }]);
        break;
        
      case 'faq':
        setCurrentStep('faq_category');
        setMessages(prev => [...prev, {
          type: 'bot',
          content: 'Select FAQ category:',
          options: ChatbotData.faqCategories
        }]);
        break;
        
      // Add other cases here
        
      default:
        if(currentStep === 'category') {
          setTicketData({ category: option.value });
          setCurrentStep('title');
          setMessages(prev => [...prev, {
            type: 'bot',
            content: 'Please enter a title for your ticket:'
          }]);
        }
    }
  };

  const handleSubmit = async () => {
    if (!inputMessage.trim()) return;

    const userMessage = { type: 'user', content: inputMessage };
    setMessages(prev => [...prev, userMessage]);
    
    if(currentStep === 'title') {
      setTicketData(prev => ({ ...prev, title: inputMessage }));
      setCurrentStep('description');
      setMessages(prev => [...prev, {
        type: 'bot',
        content: 'Please describe your issue:'
      }]);
    }
    else if(currentStep === 'description') {
      const finalData = { ...ticketData, description: inputMessage };
      
      try {
        const response = await fetch('https://jsonplaceholder.typicode.com/posts', {
          method: 'POST',
          body: JSON.stringify(finalData),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        });
        
        const data = await response.json();
        setMessages(prev => [...prev, {
          type: 'bot',
          content: `Ticket created successfully! Ticket ID: ${data.id}`
        }]);
      } catch (error) {
        setMessages(prev => [...prev, {
          type: 'bot',
          content: 'Error creating ticket. Please try again later.'
        }]);
      }
      
      setTicketData({});
      setCurrentStep('welcome');
    }
    
    setInputMessage('');
  };

  return (
    <div className={`chatbot-container ${isOpen ? 'open' : ''}`}>
      <button className="chatbot-toggle" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <FaTimes size={24} /> : <FaCommentDots size={24} />}
      </button>
      
      {isOpen && (
        <div className="chatbot-window">
          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.type}`}>
                <p>{msg.content}</p>
                {msg.options && (
                  <div className="options">
                    {msg.options.map((opt, i) => (
                      <button 
                        key={i} 
                        onClick={() => handleOptionSelect(opt)}
                        className="option-button"
                      >
                        {opt.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          
          {(currentStep === 'title' || currentStep === 'description') && (
            <div className="chat-input">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type your message..."
                onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
              />
              <button onClick={handleSubmit}>Send</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Chatbot;