import React, { useState, useEffect } from "react";
import "./LicensesUpload.css";
import { useNavigate } from "react-router-dom";

export default function LicensesUpload() {
  const [majorField, setMajorField] = useState("");
  const [subField, setSubField] = useState("");
  const [nameField, setNameField] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [version, setVersion] = useState(1);
  const [vender, setVender] = useState("");
  const [compatibleOS, setCompatibleOS] = useState("");

  const [location, setLocation] = useState("");
  const [ownership, setOwnership] = useState("");
  const [branch, setBranch] = useState("");
  const [username, setUsername] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [count, setCount] = useState(1); 
  const navigate = useNavigate();

  const BackendIP = window.location.host.split(":")[0] || "localhost";
  const BackendPort = "9094";
  const Token  = getTokenFromCookie();

  const venderOptions = ["OEM", "Self"];
  const compatibleOSOptions = ["Windows", "Linux"];

  const majorFieldData = ["operatingSystem", "Communication", "Security", "MSWordEnterprise", "Other"];

  const subFieldData = {
    operatingSystem: ["OS", "Other"],
    Communication: ["Extension", "Pri", "Sip trunk", "Voice mail", "Conference", "Queue", "FAX", "Unified Communication", "Agents", "Logger", "Sapcs", "Codeblue", "Lvr", "Other"],
    Security: ["SSL", "Other"],
    MSWordEnterprise: ["Call center acd", "Other"],
    Other: ["Custom License"],
  };

  useEffect(() => {
  
      const fetchCheckAuth = async () => {
        try {
          const response = await fetch(
            `http://${BackendIP}:${BackendPort}/api/auth/checkAuth`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${Token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          if (data.status !== 0) {
            navigate('/')
          }
        } catch (error) {
          console.log(
            "Unexpected error occurred while check auth."
          );
        }
      };
  
      fetchCheckAuth();

    }, );

  function getTokenFromCookie() {
    const cookies = document.cookie.split('; ');
    const cookieObj = cookies.find(row => row.startsWith('EAMS='));

    if (cookieObj) {
      const cookieValue = decodeURIComponent(cookieObj.split('=')[1]);
      try {
        const parsedCookie = JSON.parse(cookieValue);
        return parsedCookie.authToken;
      } catch (error) {
        console.error('Error parsing cookie:', error);
      }
    }
    return null;
  }

  const handleMajorChange = (e) => {
    setMajorField(e.target.value);
    setSubField("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!majorField || !subField || !nameField || !issueDate || !expireDate || !version || !vender || !compatibleOS || !location || !ownership || !branch || !username || !serialNumber || !count) {
      alert("Please fill in all fields.");
      return;
    }
  
    if (new Date(issueDate) > new Date(expireDate)) {
      alert("Issue Date cannot be later than Expire Date.");
      return;
    }
  
    const formData = {
      majorField,
      subField,
      nameField,
      issueDate: issueDate ? Math.floor(new Date(issueDate).getTime() / 1000) : null,
      expireDate: expireDate ? Math.floor(new Date(expireDate).getTime() / 1000) : null,
      version,
      vender,
      compatibleOS,
      location,
      ownership,
      branch,
      username,
      serialNumber,
      count,
    };
  
    try {
      const response = await fetch(`http://${BackendIP}:${BackendPort}/api/auth/licenses/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      if (response.ok && data.status === 0) {
        alert("License uploaded successfully!");
      } else {
        alert(`Error: ${data.message || "Failed to upload license."}`);
      }
    } catch (error) {
      console.error("Unexpected error occurred while uploading license:", error);
      alert("Unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="form-container">
      <h2>License Upload</h2>
      <form onSubmit={handleSubmit} className="form">
        
        {/* First Row */}
        <div className="form-row">
          <div className="form-group">
            <label>Major Field:</label>
            <select value={majorField} onChange={handleMajorChange} required style={{width: "95%"}}>
              <option value="">Select Major Field</option>
              {majorFieldData.map((field) => (
                <option key={field} value={field}>{field}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Sub Field:</label>
            <select value={subField} onChange={(e) => setSubField(e.target.value)} required disabled={!majorField} style={{width: "95%"}}>
              <option value="">Select Sub Field</option>
              {majorField && subFieldData[majorField].map((sub) => (
                <option key={sub} value={sub}>{sub}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Vender:</label>
            <select value={vender} onChange={(e) => setVender(e.target.value)} required style={{width: "95%"}}>
              <option value="">Select Vender</option>
              {venderOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Compatible OS:</label>
            <select value={compatibleOS} onChange={(e) => setCompatibleOS(e.target.value)} required style={{width: "95%"}}>
              <option value="">Select Compatible OS</option>
              {compatibleOSOptions.map((os) => (
                <option key={os} value={os}>{os}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Second Row */}
        <div className="form-row">
          <div className="form-group">
            <label>License Name:</label>
            <input type="text" value={nameField} onChange={(e) => setNameField(e.target.value)} required />
          </div>

          <div className="form-group">
            <label>Version:</label>
            <input type="number" value={version} onChange={(e) => setVersion(parseInt(e.target.value) || 1)} required />
          </div>
        </div>

        {/* Third Row */}
        <div className="form-row">
          <div className="form-group">
            <label>Issue Date:</label>
            <input type="date" value={issueDate} onChange={(e) => setIssueDate(e.target.value)} required />
          </div>

          <div className="form-group">
            <label>Expire Date:</label>
            <input type="date" value={expireDate} onChange={(e) => setExpireDate(e.target.value)} required />
          </div>
        </div>

        {/* Fifth Row */}
        <div className="form-row">
          <div className="form-group">
            <label>Location:</label>
            <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
          </div>

          <div className="form-group">
            <label>Ownership:</label>
            <input type="text" value={ownership} onChange={(e) => setOwnership(e.target.value)} required />
          </div>
        </div>

        {/* Sixth Row */}
        <div className="form-row">
          <div className="form-group">
            <label>Branch:</label>
            <input type="text" value={branch} onChange={(e) => setBranch(e.target.value)} required />
          </div>

          <div className="form-group">
            <label>Username:</label>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
          </div>
        </div>

        {/* Seventh Row */}
        <div className="form-row">
          <div className="form-group">
            <label>Serial Number:</label>
            <input type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} required />
          </div>

          <div className="form-group">
            <label>Count:</label>
            <input type="number" value={count} onChange={(e) => setCount(parseInt(e.target.value) || 1)} required />
          </div>
        </div>

        <button type="submit" className="submit-btn">Upload</button>
      </form>
    </div>
  );
}
