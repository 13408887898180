import React from "react";
import BarChart from "../../Charts/BarChart";
import PieChartComponent from "../../Charts/PieChartComponent";
import "./Dashboard.css";

// Example data for props
const barChartData1 = [
  { name: "Jan", value: 40 },
  { name: "Feb", value: 35 },
  { name: "Mar", value: 50 },
  { name: "Apr", value: 30 },
];

const pieChartData1 = [
  { name: "Group A", value: 300 },
  { name: "Group B", value: 200 },
  { name: "Group C", value: 100 },
];

const barChartData2 = [
  { name: "Jan", value: 20 },
  { name: "Feb", value: 25 },
  { name: "Mar", value: 15 },
  { name: "Apr", value: 40 },
];

const pieChartData2 = [
  { name: "Segment X", value: 150 },
  { name: "Segment Y", value: 250 },
  { name: "Segment Z", value: 200 },
];

export default function Dashboard() {

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="outer-grid">
          <div className="outer-card">
            <h3>Performance Overview</h3>
            <div className="inner-grid">
              <div className="inner-card">
                <BarChart data={barChartData1} />
              </div>
              <div className="inner-card">
                <PieChartComponent data={pieChartData1} />
              </div>
              <div className="inner-card">
                {/* <DataTable data={dataTable1} /> */}
              </div>
            </div>
          </div>

          <div className="outer-card">
            <h3>System Metrics</h3>
            <div className="inner-grid">
              <div className="inner-card">
                <PieChartComponent data={pieChartData2} />
              </div>
              <div className="inner-card">
                <BarChart data={barChartData2} />
              </div>
              <div className="inner-card">
                {/* <DataTable data={dataTable2} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
