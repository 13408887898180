import React from "react";

const InputField = ({ type = "text", placeholder = "", value, onChange, style = {}, name }) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      name={name}
      style={style}
      className="custom-input"
    />
  );
};

export default InputField;
