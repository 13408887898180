import React from "react";
import {
  PieChart as RePieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const PieChartComponent = ({
  data = [],
  colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"],
  dataKey = "value",
  outerRadius = 80,
  width = "100%",
  height = 250,
}) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <RePieChart>
        <Pie
          data={data}
          dataKey={dataKey}
          cx="50%"
          cy="50%"
          outerRadius={outerRadius}
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
      </RePieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
