import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { sidebarData } from "./SidebarData";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { Outlet } from "react-router-dom";
import { MdMenuBook } from "react-icons/md";
import "./Sidebar.css";
import Chatbot from "../Chatbot/Chatbot";

export default function Sidebar({ isSidebarOpen, setIsSidebarOpen, activeTab }) {
  const [openSubmenus, setOpenSubmenus] = useState({});
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const sidebarRef = useRef(null);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const toggleSubmenu = (label) => {
    setOpenSubmenus((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const currentSidebarItems = (sidebarData[activeTab] || []).slice(1);

  const handleSidebarItemClick = (item) => {
    if (item.disabled) return; // Prevent click if disabled

    if (item.onClick) {
      item.onClick();
    } else {
      navigate(item.path);
      setIsSidebarOpen(false);
    }
  };

  const GoLink = () => {
    window.open("http://170.187.248.8/ACS-tr-069-server/", "_blank");
  };

  const GoListDevice = () => {
    navigate("/5g-management/amf");
  };

  // Close sidebar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <>
      <div className="navbar">
        {isSidebarOpen && (
          <div ref={sidebarRef} className="sidebar">
            <div className="sidebar-toggle" onClick={() => setIsSidebarOpen(false)}>
              <IoMdArrowBack />
            </div>

            <ul className="sidebar-menu">
              {currentSidebarItems.map((item, index) => (
                <React.Fragment key={index}>
                  <li
                    onClick={() => {
                      if (!item.disabled) {
                        item.submenu ? toggleSubmenu(item.label) : handleSidebarItemClick(item);
                      }
                    }}
                    className={`sidebar-item ${item.disabled ? "disabled" : ""}`}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </li>
                  {item.submenu && openSubmenus[item.label] && (
                    <ul className="submenu">
                      {item.submenu.map((subItem, subIndex) => (
                        <li
                          key={subIndex}
                          onClick={() => !subItem.disabled && handleSidebarItemClick(subItem)}
                          className={`sidebar-item ${subItem.disabled ? "disabled" : ""}`}
                        >
                          {subItem.icon}
                          <span>{subItem.label}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}
        {!isSidebarOpen && (
          <div className="sidebar-toggle-closed" onClick={() => setIsSidebarOpen(true)}>
            <IoMdArrowForward />
          </div>
        )}
      </div>

      <Outlet />

      <Chatbot/>
      {/* <div className="voice-button">
        <MdMenuBook size={27} onClick={handleMenuClick} />
      </div>

      {showMenu && (
        <div className="voice-popup">
          <div className="voice-popup-content">
            <button className="menu-item" onClick={GoLink}>App deb</button>
            <button className="menu-item" onClick={GoListDevice}>AMF config</button>
          </div>
        </div>
      )} */}
    </>
  );
}
