import React, { useState } from "react";
import { FaBell } from "react-icons/fa";
import "./TopBar.css";
import Logo from "../Image/logoDark.png";
import "react-dropdown/style.css";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

export const TopBar = () => {
  const [profileShow, setProfileShow] = useState(false);
  const navigate = useNavigate();

  const OpenProfile = () => {
    setProfileShow(!profileShow);
  };

  const clearCookie = () => {
    document.cookie = "EAMS=; path=/; max-age=0";
    navigate("/");
  };

  function getDataFromCookie() {
    const cookies = document.cookie.split("; ");
    const cookieObj = cookies.find((row) => row.startsWith("EAMS="));

    if (cookieObj) {
      const cookieValue = decodeURIComponent(cookieObj.split("=")[1]);
      try {
        const parsedCookie = JSON.parse(cookieValue);
        return {
          authToken: parsedCookie.authToken,
          userRole: parsedCookie.userRole,
          username: parsedCookie.username,
        };
      } catch (error) {
        console.error("Error parsing cookie:", error);
      }
    }
    return null;
  }

  const userData = getDataFromCookie();

  return (
    <div className="top-bar">
      {/* Logo and EAMS text */}
      <div className="logo-container">
        <img className="logo-img" src={Logo} alt="Logo" />
        <div className="eams-text">
          <h2 className="eams-title">EAMS</h2>
          <h2 className="eams-title22" style={{ marginTop: "-10px" }}>
            Enterprise Asset Management System
          </h2>
        </div>
      </div>

      {/* Global Search Bar */}
      <div className="search-bar-container">
        {/* Notification and Profile Icons */}
        <div className="icon-container">
          <FaBell className="top-bar-icon" title="Notifications" />
          <CgProfile
            className="top-bar-icon"
            title="Profile"
            onClick={OpenProfile}
          />
        </div>
      </div>

      {/* Profile Dropdown */}
      <div className={`ProfileDropDown ${profileShow ? "show" : ""}`}>
        <p>User: {userData.username}</p>
        <p>Role: {userData.userRole}</p>
        <button onClick={clearCookie}>Logout</button>
      </div>
    </div>
  );
};